import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import axios from "axios";
import { getDateWithFormat } from "helpers/Utils"
import { servicePath } from "constants/defaultValues";
import surveyListData from "data/survey.list.json"
import { SERVICE_LIST_GET_LIST, SERVICE_LIST_ADD_ITEM, SERVICE_LIST_EDIT_ITEM, SERVICE_LIST_DELETE_ITEM} from "../actions"

import {
    getServiceListSuccess,
    getServiceListError,
    addServiceItemSuccess,
    addServiceItemError,
    editServiceItemSuccess,
    editServiceItemError,
    deleteServiceItemSuccess,
    deleteServiceItemError,
} from "./actions";

const apiUrl = `${servicePath}/services`;

//#region Service List

export function* watchGetList() {
    yield takeEvery(SERVICE_LIST_GET_LIST, getServiceListItems)
}

function* getServiceListItems() {
    try {
        const response = yield call(getServiceListRequest)
        yield put(getServiceListSuccess(response))
    } catch (error) {
        yield put(getServiceListError(error))
    }
}

const getServiceListRequest = async () => {
    try {
        let response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion


//#region Add Service

export function* wathcAddItem() {
    yield takeEvery(SERVICE_LIST_ADD_ITEM, addServiceItem)
}

function* addServiceItem({ payload }) {
    try {
        const response = yield call(addServiceItemRequest, payload)
        yield put(addServiceItemSuccess(response))
    } catch (error) {
        yield put(addServiceItemError(error))
    }
}


const addServiceItemRequest = async (item) => { 
    try {
        await axios.post(apiUrl, item);
        let response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion

//#region Edit Service

export function* wathcEditItem() {
    yield takeEvery(SERVICE_LIST_EDIT_ITEM, editServiceItem)
}

function* editServiceItem({ payload }) {
    try {
        const response = yield call(editServiceItemRequest, payload)
        yield put(editServiceItemSuccess(response))
    } catch (error) {
        yield put(editServiceItemError(error))
    }
}


const editServiceItemRequest = async (item) => { 
    try {
        await axios.patch(apiUrl + "/" + item.id, item);
        let response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion

//#region Delete Service

export function* wathcDeleteItem() {
    yield takeEvery(SERVICE_LIST_DELETE_ITEM, deleteServiceItem)
}

function* deleteServiceItem({ payload }) {
    try {
        yield call(deleteServiceItemRequest, payload)
        yield put(deleteServiceItemSuccess(payload))
    } catch (error) {
        yield put(deleteServiceItemError(error))
    }
}


const deleteServiceItemRequest = async (item) => {
    
    try {
        let res = await axios.delete(apiUrl + "/" + item);
        //let response = await axios.get(apiUrl);
        
        console.log("del in res", res.data)
        return response.data;
    } catch (error) {
        console.log("err", error)
        return error;
    }
}

//#endregion

export default function* rootSaga() {
    yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcEditItem), fork(wathcDeleteItem)])
}
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "helpers/Firebase";
import { adminRoot, loginPage } from "constants/defaultValues";
import { setCurrentUser } from "helpers/Utils";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { LOGIN_USER, LOGOUT_USER, FORGOT_PASSWORD, RESET_PASSWORD } from "../actions";

import { loginUserSuccess, loginUserError, forgotPasswordSuccess, forgotPasswordError, resetPasswordSuccess, resetPasswordError } from "./actions";

export function* watchLoginUser() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
	let param = {
		password: password,
		username: email,
	}

	try {
		let response = await axios.post(servicePath + '/auth/signin', param);
		console.log("er", response.data)
		return response.data;
	} catch (error) {

	}
};

function* loginWithEmailPassword({ payload }) {
	const { email, password } = payload.user;
	const { history } = payload;
	try {
		const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
		
		if (!loginUser.message) {
			setCurrentUser(loginUser);
			yield put(loginUserSuccess(loginUser));

			history.push(adminRoot);
		} else {
			yield put(loginUserError(loginUser.message));
		}
	} catch (error) {
		yield put(loginUserError(error));
	}
}

export function* watchLogoutUser() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
	await auth
		.signOut()
		.then((user) => user)
		.catch((error) => error);
	history.replace(loginPage);
};

function* logout({ payload }) {
	const { history } = payload;
	setCurrentUser();
	yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
	// eslint-disable-next-line no-return-await
	return await auth
		.sendPasswordResetEmail(email)
		.then((user) => user)
		.catch((error) => error);
};

function* forgotPassword({ payload }) {
	const { email } = payload.forgotUserMail;
	try {
		const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
		if (!forgotPasswordStatus) {
			yield put(forgotPasswordSuccess("success"));
		} else {
			yield put(forgotPasswordError(forgotPasswordStatus.message));
		}
	} catch (error) {
		yield put(forgotPasswordError(error));
	}
}

export function* watchResetPassword() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
	// eslint-disable-next-line no-return-await
	return await auth
		.confirmPasswordReset(resetPasswordCode, newPassword)
		.then((user) => user)
		.catch((error) => error);
};

function* resetPassword({ payload }) {
	const { newPassword, resetPasswordCode } = payload;
	try {
		const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
		if (!resetPasswordStatus) {
			yield put(resetPasswordSuccess("success"));
		} else {
			yield put(resetPasswordError(resetPasswordStatus.message));
		}
	} catch (error) {
		yield put(resetPasswordError(error));
	}
}

export default function* rootSaga() {
	yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchForgotPassword), fork(watchResetPassword)]);
}

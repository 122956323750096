import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';

import serviceListSagas from './service/saga';
import activeServiceListSagas from './activeService/saga';
import activeAuthorListSagas from './activeAuthor/saga';
import authorListSagas from './author/saga';
import guideListSagas from './guide/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    activeAuthorListSagas(),

    serviceListSagas(),
    authorListSagas(),
    guideListSagas(),
    activeServiceListSagas()
  ]);
}

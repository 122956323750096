import {
    AUTHOR_LIST_GET_LIST,
    AUTHOR_LIST_GET_LIST_SUCCESS,
    AUTHOR_LIST_GET_LIST_ERROR,
    AUTHOR_LIST_ADD_ITEM,
    AUTHOR_LIST_ADD_ITEM_SUCCESS,
    AUTHOR_LIST_ADD_ITEM_ERROR,
    AUTHOR_LIST_EDIT_ITEM,
    AUTHOR_LIST_EDIT_ITEM_ERROR,
    AUTHOR_LIST_EDIT_ITEM_SUCCESS,AUTHOR_LIST_DELETE_ITEM, AUTHOR_LIST_DELETE_ITEM_SUCCESS
} from "../actions"

const INIT_STATE = {
    authorItems: [],
    error: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTHOR_LIST_GET_LIST:
            return { ...state, loading: false }
        case AUTHOR_LIST_GET_LIST_SUCCESS:
            return { ...state, loading: true, authorItems: action.payload }
        case AUTHOR_LIST_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload }
        case AUTHOR_LIST_ADD_ITEM:
            return { ...state, loading: false }
        case AUTHOR_LIST_EDIT_ITEM:{
            state.authorItems = state.authorItems.map((element)=>{
                if(element.id == action.payload.id){
                    return action.payload
                } else{
                    return element
                }
            })
            return { ...state, loading: false }}
        case AUTHOR_LIST_EDIT_ITEM_SUCCESS:
            return { ...state, loading: true, authorItems: action.payload }
        case AUTHOR_LIST_EDIT_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        case AUTHOR_LIST_ADD_ITEM_SUCCESS:
            return { ...state, loading: true, authorItems: action.payload }
        case AUTHOR_LIST_ADD_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload, }
        case AUTHOR_LIST_DELETE_ITEM:
            return { ...state, loading: false }
        case AUTHOR_LIST_DELETE_ITEM_SUCCESS: {
            const authorItems = state.authorItems.filter((element)=> element.id !== action.payload )
            return { ...state, authorItems: [...authorItems], loading: true }
        }
        default:
            return { ...state }
    }
}

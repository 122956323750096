import { LOGIN_USER,LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, LOGOUT_USER, FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from "../actions";

export const loginUser = (user, history) => ({
	type: LOGIN_USER,
	payload: { user, history },
});
export const loginUserSuccess = (user) => ({
	type: LOGIN_USER_SUCCESS,
	payload: user,
});
export const loginUserError = (message) => ({
	type: LOGIN_USER_ERROR,
	payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
	type: FORGOT_PASSWORD,
	payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
	type: FORGOT_PASSWORD_SUCCESS,
	payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
	type: FORGOT_PASSWORD_ERROR,
	payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
	type: RESET_PASSWORD,
	payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
	type: RESET_PASSWORD_SUCCESS,
	payload: newPassword,
});
export const resetPasswordError = (message) => ({
	type: RESET_PASSWORD_ERROR,
	payload: { message },
});

export const logoutUser = (history) => ({
	type: LOGOUT_USER,
	payload: { history },
});

import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { SERVICE_LIST_GET_LIST_ACTIVE } from "../actions"

import {
    getActiveServiceListSuccess,
    getActiveServiceListError,
} from "./actions";

const apiUrl = `${servicePath}/services`;



//#region Service Active List

export function* watchGetActiveList() {
    yield takeEvery(SERVICE_LIST_GET_LIST_ACTIVE, getActiveServiceListItems)
}

function* getActiveServiceListItems() {
    try {
        const response = yield call(getActiveServiceListRequest)
        yield put(getActiveServiceListSuccess(response))
    } catch (error) {
        yield put(getActiveServiceListError(error))
    }
}

const getActiveServiceListRequest = async () => {
    try {
        let response = await axios.get(apiUrl+ '/active');
        console.log('response++++++++++Authors@@@@@@@@@@@@@@', response)
        return response.data;
    } catch (error) {
        return error;
    }
}
//#endregion

export default function* rootSaga() {
    yield all([fork(watchGetActiveList)])
}
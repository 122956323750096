import {
    AUTHOR_LIST_GET_LIST,
    AUTHOR_LIST_GET_LIST_SUCCESS,
    AUTHOR_LIST_GET_LIST_ERROR,
    AUTHOR_LIST_ADD_ITEM,
    AUTHOR_LIST_EDIT_ITEM,
    AUTHOR_LIST_ADD_ITEM_SUCCESS,
    AUTHOR_LIST_ADD_ITEM_ERROR,
    AUTHOR_LIST_EDIT_ITEM_SUCCESS,
    AUTHOR_LIST_EDIT_ITEM_ERROR,
    AUTHOR_LIST_DELETE_ITEM,
    AUTHOR_LIST_DELETE_ITEM_SUCCESS,
    AUTHOR_LIST_DELETE_ITEM_ERROR,
} from "../actions"

export const getAuthorList = () => ({
    type: AUTHOR_LIST_GET_LIST,
})

export const getAuthorListSuccess = (items) => ({
    type: AUTHOR_LIST_GET_LIST_SUCCESS,
    payload: items,
})

export const getAuthorListError = (error) => ({
    type: AUTHOR_LIST_GET_LIST_ERROR,
    payload: error,
})

export const addAuthorItem = (item) => ({
    type: AUTHOR_LIST_ADD_ITEM,
    payload: item,
})
export const addAuthorItemSuccess = (items) => ({
    type: AUTHOR_LIST_ADD_ITEM_SUCCESS,
    payload: items,
})

export const addAuthorItemError = (error) => ({
    type: AUTHOR_LIST_ADD_ITEM_ERROR,
    payload: error,
})

export const editAuthorItem = (item) => ({
    type: AUTHOR_LIST_EDIT_ITEM,
    payload: item,
})
export const editAuthorItemSuccess = (items) => ({
    type: AUTHOR_LIST_EDIT_ITEM_SUCCESS,
    payload: items,
})

export const editAuthorItemError = (error) => ({
    type: AUTHOR_LIST_EDIT_ITEM_ERROR,
    payload: error,
})

export const deleteAuthorItem = (item) => ({
    type: AUTHOR_LIST_DELETE_ITEM,
    payload: item,
})
export const deleteAuthorItemSuccess = (items) => ({
    type: AUTHOR_LIST_DELETE_ITEM_SUCCESS,
    payload: items,
})

export const deleteAuthorItemError = (error) => ({
    type: AUTHOR_LIST_DELETE_ITEM_ERROR,
    payload: error,
})



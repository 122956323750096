import {
    SERVICE_LIST_GET_LIST_ACTIVE,
    SERVICE_LIST_GET_LIST_SUCCESS_ACTIVE,
    SERVICE_LIST_GET_LIST_ERROR_ACTIVE,
} from "../actions"

// ******************************************************************************
export const getActiveServiceList = () => ({
    type: SERVICE_LIST_GET_LIST_ACTIVE,
})

export const getActiveServiceListSuccess = (items) => ({
    type: SERVICE_LIST_GET_LIST_SUCCESS_ACTIVE,
    payload: items,
})

export const getActiveServiceListError = (error) => ({
    type: SERVICE_LIST_GET_LIST_ERROR_ACTIVE,
    payload: error,
})
// *******************************************************************************
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import axios from "axios"; 
import { servicePath } from "constants/defaultValues";
import { AUTHOR_LIST_GET_LIST, AUTHOR_LIST_ADD_ITEM, AUTHOR_LIST_EDIT_ITEM, AUTHOR_LIST_DELETE_ITEM } from "../actions"

import {
    getAuthorListSuccess,
    getAuthorListError,
    addAuthorItemSuccess,
    addAuthorItemError,
    editAuthorItemSuccess,
    editAuthorItemError,
    deleteAuthorItemSuccess,
    deleteAuthorItemError,
} from "./actions";

const apiUrl = `${servicePath}/authors`;

//#region Author List

export function* watchGetList() {
    yield takeEvery(AUTHOR_LIST_GET_LIST, getAuthorListItems)
}

function* getAuthorListItems() {
    try {
        const response = yield call(getAuthorListRequest)
        yield put(getAuthorListSuccess(response))
    } catch (error) {
        yield put(getAuthorListError(error))
    }
}

const getAuthorListRequest = async () => {
    try {
        let response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion


//#region Add Author

export function* wathcAddItem() {
    yield takeEvery(AUTHOR_LIST_ADD_ITEM, addAuthorItem)
}

function* addAuthorItem({ payload }) {
    try {
        const response = yield call(addAuthorItemRequest, payload)
        yield put(addAuthorItemSuccess(response))
    } catch (error) {
        yield put(addAuthorItemError(error))
    }
}


const addAuthorItemRequest = async (item) => { 
    try {
            await axios.post(apiUrl, item);
            let response = await axios.get(apiUrl);
            return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion

//#region Edit Author

export function* wathcEditItem() {
    yield takeEvery(AUTHOR_LIST_EDIT_ITEM, editAuthorItem)
}

function* editAuthorItem({ payload }) {
    try {
        const response = yield call(editAuthorItemRequest, payload)
        yield put(editAuthorItemSuccess(response))
    } catch (error) {
        yield put(editAuthorItemError(error))
    }
}


const editAuthorItemRequest = async (item) => { 
    try {
        let res = await axios.patch(apiUrl + "/" + item.id, item);
        let response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion

//#region Delete Author

export function* wathcDeleteItem() {
    yield takeEvery(AUTHOR_LIST_DELETE_ITEM, deleteAuthorItem)
}

function* deleteAuthorItem({ payload }) {
    try {
        yield call(deleteAuthorItemRequest, payload)
        yield put(deleteAuthorItemSuccess(payload))
        
    } catch (error) {
        yield put(deleteAuthorItemError(error))
    }
}


const deleteAuthorItemRequest = async (item) => { 
    try {
        let res = await axios.delete(apiUrl + "/" + item);
        return res.data;
    } catch (error) {
        return error;
    }
}

//#endregion


export default function* rootSaga() {
    yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcEditItem), fork(wathcDeleteItem)])
}
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { AUTHOR_LIST_GET_LIST_ACTIVE } from "../actions"

import {
    getActiveAuthorListSuccess,
    getActiveAuthorListError
} from "./actions";

const apiUrl = `${servicePath}/authors`;

//#region Author List

export function* watchGetActiveList() {
    yield takeEvery(AUTHOR_LIST_GET_LIST_ACTIVE, getActiveAuthorListItems)
}

function* getActiveAuthorListItems() {
    try {
        const response = yield call(getActiveAuthorListRequest)
        yield put(getActiveAuthorListSuccess(response))
    } catch (error) {
        yield put(getActiveAuthorListError(error))
    }
}

const getActiveAuthorListRequest = async () => {
    try {
        let response = await axios.get(apiUrl+'/active');
        console.log('Author response---------------', response)
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion



export default function* rootSaga() {
    yield all([fork(watchGetActiveList)])
}
import {
    AUTHOR_LIST_GET_LIST_ACTIVE,
    AUTHOR_LIST_GET_LIST_SUCCESS_ACTIVE,
    AUTHOR_LIST_GET_LIST_ERROR_ACTIVE,
} from "../actions"

export const getActiveAuthorList = () => ({
    type: AUTHOR_LIST_GET_LIST_ACTIVE,
})

export const getActiveAuthorListSuccess = (items) => ({
    type: AUTHOR_LIST_GET_LIST_SUCCESS_ACTIVE,
    payload: items,
})

export const getActiveAuthorListError = (error) => ({
    type: AUTHOR_LIST_GET_LIST_ERROR_ACTIVE,
    payload: error,
})
import {
    SERVICE_LIST_GET_LIST_ACTIVE,
    SERVICE_LIST_GET_LIST_SUCCESS_ACTIVE,
    SERVICE_LIST_GET_LIST_ERROR_ACTIVE,
} from "../actions"

const INIT_STATE = {
    activeServiceItems: [],
    error: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SERVICE_LIST_GET_LIST_ACTIVE:
            return { ...state, loading: false }
        case SERVICE_LIST_GET_LIST_SUCCESS_ACTIVE:
            return { ...state, loading: true, activeServiceItems: action.payload }
        case SERVICE_LIST_GET_LIST_ERROR_ACTIVE:
            return { ...state, loading: true, error: action.payload }
        default:
            return { ...state }
    }
}

import {
    SERVICE_LIST_GET_LIST,
    SERVICE_LIST_GET_LIST_SUCCESS,
    SERVICE_LIST_GET_LIST_ERROR,
    SERVICE_LIST_ADD_ITEM,
    SERVICE_LIST_ADD_ITEM_SUCCESS,
    SERVICE_LIST_ADD_ITEM_ERROR,
    SERVICE_LIST_EDIT_ITEM,
    SERVICE_LIST_EDIT_ITEM_SUCCESS,
    SERVICE_LIST_EDIT_ITEM_ERROR,
    SERVICE_LIST_DELETE_ITEM,
    SERVICE_LIST_DELETE_ITEM_SUCCESS,
    SERVICE_LIST_DELETE_ITEM_ERROR,
} from "../actions"

export const getServiceList = () => ({
    type: SERVICE_LIST_GET_LIST,
})

export const getServiceListSuccess = (items) => ({
    type: SERVICE_LIST_GET_LIST_SUCCESS,
    payload: items,
})

export const getServiceListError = (error) => ({
    type: SERVICE_LIST_GET_LIST_ERROR,
    payload: error,
})

export const addServiceItem = (item) => ({
    type: SERVICE_LIST_ADD_ITEM,
    payload: item,
})

export const addServiceItemSuccess = (items) => ({
    type: SERVICE_LIST_ADD_ITEM_SUCCESS,
    payload: items,
})

export const addServiceItemError = (error) => ({
    type: SERVICE_LIST_ADD_ITEM_ERROR,
    payload: error,
})

export const deleteServiceItem = (item) => ({
    type: SERVICE_LIST_DELETE_ITEM,
    payload: item,
})

export const deleteServiceItemSuccess = (items) => ({
    type: SERVICE_LIST_DELETE_ITEM_SUCCESS,
    payload: items,
})

export const deleteServiceItemError = (error) => ({
    type: SERVICE_LIST_DELETE_ITEM_ERROR,
    payload: error,
})

export const editServiceItem = (item) => ({
    type: SERVICE_LIST_EDIT_ITEM,
    payload: item,
})

export const editServiceItemSuccess = (items) => ({
    type: SERVICE_LIST_EDIT_ITEM_SUCCESS,
    payload: items,
})

export const editServiceItemError = (error) => ({
    type: SERVICE_LIST_EDIT_ITEM_ERROR,
    payload: error,
})

import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';

import serviceListApp from './service/reducer';
import activeServiceListApp from './activeService/reducer';
import authorListApp from './author/reducer';
import activeAuthorListApp from './activeAuthor/reducer';
import guideListApp from './guide/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  activeServiceListApp,
  activeAuthorListApp,

  serviceListApp,
  authorListApp,
  guideListApp
});

export default reducers;

import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { GUIDE_LIST_GET_LIST, GUIDE_LIST_ADD_ITEM, GUIDE_LIST_REARRANGE_ITEM, GUIDE_LIST_EDIT_ITEM, GUIDE_LIST_DELETE_ITEM } from "../actions"

import {
    getGuideListSuccess,
    getGuideListError,
    addGuideItemSuccess,
    addGuideItemError,
    editGuideItemSuccess,
    editGuideItemError,
    deleteGuideItemSuccess,
    deleteGuideItemError,
    rearrangeItemSuccess,
    rearrangeItemError,
} from "./actions";

var apiUrl = `${servicePath}/guides`;

//#region Guide List

export function* watchGetList() {
    yield takeEvery(GUIDE_LIST_GET_LIST, getGuideListItems)
}

function* getGuideListItems({ payload }) {
    try {
        const response = yield call(getGuideListRequest, payload)
        yield put(getGuideListSuccess(response))
    } catch (error) {
        yield put(getGuideListError(error))
    }
}

const getGuideListRequest = async (isOrthoJourney) => {
    try {

        if (isOrthoJourney) {
            let response = await axios.get(apiUrl + "/OrthoJourney");
            return response.data;
        } else {
            let response = await axios.get(apiUrl);
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

//#endregion


//#region Add Guide

export function* wathcAddItem() {
    yield takeEvery(GUIDE_LIST_ADD_ITEM, addGuideItem)
}

function* addGuideItem({ payload }) {
    try {
        const response = yield call(addGuideItemRequest, payload)
        yield put(addGuideItemSuccess(response))
    } catch (error) {
        yield put(addGuideItemError(error))
    }
}


const addGuideItemRequest = async (item) => {
    try {
        if (item.edit) {
            await axios.patch(apiUrl + "/" + item.id, item);
        } else {
            await axios.post(apiUrl, item);
        }

        if (item.isOrthoJourney) {
            let response = await axios.get(apiUrl + "/OrthoJourney");
            return response.data;
        } else {
            let response = await axios.get(apiUrl);
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

//#endregion

//#region Edit Guide

export function* wathcEditItem() {
    yield takeEvery(GUIDE_LIST_EDIT_ITEM, editGuideItem)
}

function* editGuideItem({ payload }) {
    try {
        const response = yield call(editGuideItemRequest, payload)
        yield put(editGuideItemSuccess(response))
    } catch (error) {
        yield put(editGuideItemError(error))
    }
}


const editGuideItemRequest = async (item) => {
    try {
        await axios.patch(apiUrl + "/" + item.id, item);

        if (item.isOrthoJourney) {
            let response = await axios.get(apiUrl + "/OrthoJourney");
            return response.data;
        } else {
            let response = await axios.get(apiUrl);
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

//#endregion

//#region Delete Guide

export function* wathcDeleteItem() {
    yield takeEvery(GUIDE_LIST_DELETE_ITEM, deleteGuideItem)
}

function* deleteGuideItem({ payload }) {
    try {
        yield call(deleteGuideItemRequest, payload)
        yield put(deleteGuideItemSuccess(payload))
    } catch (error) {
        yield put(deleteGuideItemError(error))
    }
}


const deleteGuideItemRequest = async (item) => {
    try {
        let res = await axios.delete(apiUrl + "/" + item);
        return res.data;
    } catch (error) {
        return error;
    }
}

//#endregion

//#region Rearange Guide`

export function* wathcRearangeItem() {
    yield takeEvery(GUIDE_LIST_REARRANGE_ITEM, rearrangeItem)
}

function* rearrangeItem({ payload }) {
    try {
        const response = yield call(rearrangeGuideItemRequest, payload)
        yield put(rearrangeItemSuccess(response))
    } catch (error) {
        yield put(rearrangeItemError(error))
    }
}


const rearrangeGuideItemRequest = async (item) => {
    try {
        await axios.post(apiUrl + "/rearrange", item);
        let response = await axios.get(apiUrl + "/OrthoJourney");
        return response.data;
    } catch (error) {
        return error;
    }
}

//#endregion


export default function* rootSaga() {
    yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcRearangeItem), fork(wathcEditItem), fork(wathcDeleteItem)])
}
import {
    GUIDE_LIST_GET_LIST,
    GUIDE_LIST_GET_LIST_SUCCESS,
    GUIDE_LIST_GET_LIST_ERROR,
    GUIDE_LIST_ADD_ITEM,
    GUIDE_LIST_ADD_ITEM_SUCCESS,
    GUIDE_LIST_ADD_ITEM_ERROR,
    GUIDE_LIST_EDIT_ITEM,
    GUIDE_LIST_EDIT_ITEM_SUCCESS,
    GUIDE_LIST_EDIT_ITEM_ERROR,
    GUIDE_LIST_DELETE_ITEM,
    GUIDE_LIST_DELETE_ITEM_SUCCESS,
    GUIDE_LIST_DELETE_ITEM_ERROR,
    GUIDE_LIST_REARRANGE_ITEM,
    GUIDE_LIST_REARRANGE_ITEM_SUCCESS,
    GUIDE_LIST_REARRANGE_ITEM_ERROR
} from "../actions"

export const getGuideList = (isOrthoJourney) => ({
    type: GUIDE_LIST_GET_LIST,
    payload: isOrthoJourney,
})

export const getGuideListSuccess = (items) => ({
    type: GUIDE_LIST_GET_LIST_SUCCESS,
    payload: items,
})

export const getGuideListError = (error) => ({
    type: GUIDE_LIST_GET_LIST_ERROR,
    payload: error,
})

export const addGuideItem = (item) => ({
    type: GUIDE_LIST_ADD_ITEM,
    payload: item,
})

export const addGuideItemSuccess = (items) => ({
    type: GUIDE_LIST_ADD_ITEM_SUCCESS,
    payload: items,
})

export const addGuideItemError = (error) => ({
    type: GUIDE_LIST_ADD_ITEM_ERROR,
    payload: error,
})

export const editGuideItem = (item) => ({
    type: GUIDE_LIST_EDIT_ITEM,
    payload: item,
})

export const editGuideItemSuccess = (items) => ({
    type: GUIDE_LIST_EDIT_ITEM_SUCCESS,
    payload: items,
})

export const editGuideItemError = (error) => ({
    type: GUIDE_LIST_EDIT_ITEM_ERROR,
    payload: error,
})

export const deleteGuideItem = (item) => ({
    type: GUIDE_LIST_DELETE_ITEM,
    payload: item,
})

export const deleteGuideItemSuccess = (items) => ({
    type: GUIDE_LIST_DELETE_ITEM_SUCCESS,
    payload: items,
})

export const deleteGuideItemError = (error) => ({
    type: GUIDE_LIST_DELETE_ITEM_ERROR,
    payload: error,
})

export const rearrangeItem = (item) => ({
    type: GUIDE_LIST_REARRANGE_ITEM,
    payload: item,
})

export const rearrangeItemSuccess = (items) => ({
    type: GUIDE_LIST_REARRANGE_ITEM_SUCCESS,
    payload: items,
})

export const rearrangeItemError = (error) => ({
    type: GUIDE_LIST_REARRANGE_ITEM_ERROR,
    payload: error,
})

import {
    GUIDE_LIST_GET_LIST,
    GUIDE_LIST_GET_LIST_SUCCESS,
    GUIDE_LIST_GET_LIST_ERROR,
    GUIDE_LIST_ADD_ITEM,
    GUIDE_LIST_ADD_ITEM_SUCCESS,
    GUIDE_LIST_ADD_ITEM_ERROR,
    GUIDE_LIST_EDIT_ITEM,
    GUIDE_LIST_EDIT_ITEM_SUCCESS,
    GUIDE_LIST_EDIT_ITEM_ERROR,
    GUIDE_LIST_DELETE_ITEM,
    GUIDE_LIST_DELETE_ITEM_SUCCESS,
    GUIDE_LIST_DELETE_ITEM_ERROR,
    GUIDE_LIST_REARRANGE_ITEM,
    GUIDE_LIST_REARRANGE_ITEM_SUCCESS,
    GUIDE_LIST_REARRANGE_ITEM_ERROR
} from "../actions"

const INIT_STATE = {
    guideItems: [],
    error: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GUIDE_LIST_GET_LIST:
            return { ...state, loading: false }
        case GUIDE_LIST_GET_LIST_SUCCESS:
            return { ...state, loading: true, guideItems: action.payload }
        case GUIDE_LIST_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload }
        case GUIDE_LIST_ADD_ITEM:
            return { ...state, loading: false }
        case GUIDE_LIST_ADD_ITEM_SUCCESS:
            return { ...state, loading: true, guideItems: action.payload }
        case GUIDE_LIST_ADD_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        case GUIDE_LIST_EDIT_ITEM:
            return { ...state, loading: false }
        case GUIDE_LIST_EDIT_ITEM_SUCCESS:
            return { ...state, loading: true, guideItems: action.payload }
        case GUIDE_LIST_EDIT_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        case GUIDE_LIST_DELETE_ITEM:
            return { ...state, loading: false }
        case GUIDE_LIST_DELETE_ITEM_SUCCESS:{
            
            const guideItems = state.guideItems.filter((element)=> element.id !== action.payload )
            console.log("guideItems reducer", guideItems,"payload" ,action.payload)
            return { ...state, guideItems: [...guideItems], loading: true }
        }
        case GUIDE_LIST_DELETE_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        case GUIDE_LIST_REARRANGE_ITEM:
            return { ...state, loading: false }
        case GUIDE_LIST_REARRANGE_ITEM_SUCCESS:
            return { ...state, loading: true, guideItems: action.payload }
        case GUIDE_LIST_REARRANGE_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        default:
            return { ...state }
    }
}

import {
    SERVICE_LIST_GET_LIST,
    SERVICE_LIST_GET_LIST_SUCCESS,
    SERVICE_LIST_GET_LIST_ERROR,
    SERVICE_LIST_ADD_ITEM,
    SERVICE_LIST_ADD_ITEM_SUCCESS,
    SERVICE_LIST_ADD_ITEM_ERROR,
    SERVICE_LIST_EDIT_ITEM,
    SERVICE_LIST_EDIT_ITEM_SUCCESS,
    SERVICE_LIST_EDIT_ITEM_ERROR,
    SERVICE_LIST_DELETE_ITEM,
    SERVICE_LIST_DELETE_ITEM_SUCCESS,
    SERVICE_LIST_DELETE_ITEM_ERROR,
} from "../actions"

const INIT_STATE = { 
    serviceItems: [],
    error: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SERVICE_LIST_GET_LIST:
            return { ...state, loading: false }
        case SERVICE_LIST_GET_LIST_SUCCESS:
            return { ...state, loading: true, serviceItems: action.payload }
        case SERVICE_LIST_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload }
        case SERVICE_LIST_ADD_ITEM:
            return { ...state, loading: false }
        case SERVICE_LIST_ADD_ITEM_SUCCESS:
            return { ...state, loading: true, serviceItems: action.payload }
        case SERVICE_LIST_ADD_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        case SERVICE_LIST_EDIT_ITEM:  
            return { ...state, loading: false }
        case SERVICE_LIST_EDIT_ITEM_SUCCESS:
            return { ...state, loading: true, serviceItems: action.payload }
        case SERVICE_LIST_EDIT_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        case SERVICE_LIST_DELETE_ITEM: 
            return { ...state, loading: false }
        case SERVICE_LIST_DELETE_ITEM_SUCCESS: {
            const serviceItems = state.serviceItems.filter((element)=> element.id !== action.payload )
            return { ...state, loading: true, serviceItems:[ ...serviceItems] }
        }
        case SERVICE_LIST_DELETE_ITEM_ERROR:
            return { ...state, loading: true, error: action.payload }
        default:
            return { ...state }
    }
}
